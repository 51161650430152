import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "button", "tooltip"]

  select(event) {
    this.inputTarget.value = event.currentTarget.getAttribute("data-value-id")

    // изменить стили для невыбранного элемента
    this.element.querySelectorAll(".full-screen-card").forEach((element) => {
      element.classList.add("border-red-500", "opacity-50", "border-3")
    })

    // изменить стиль границы для выбранного элемента
    event.currentTarget.classList.remove("border-red-500", "opacity-50")
    event.currentTarget.classList.add("border-green-500")

    this.enableSubmitBtn()
  }

  enableSubmitBtn() {
    if (this.buttonTarget.disabled) {
      this.buttonTarget.disabled = false

      this.buttonTarget.removeAttribute("data-tooltip-target")
      this.buttonTarget.removeAttribute("data-tooltip-style")

      this.tooltipTarget.remove()
    }
  }
}
