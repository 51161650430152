import { Controller as BaseController } from "@hotwired/stimulus"

import GLightbox from "glightbox"
import "glightbox/dist/css/glightbox.min.css"

export class Controller extends BaseController {
  static values = {
    galleryId: String,
    trailerExternal: Boolean,
  }

  connect() {
    this.lightbox = GLightbox({
      selector: `.poster_${this.galleryIdValue}`,
      touchNavigation: true,
      loop: false,
      autoplayVideos: true,
      descPosition: "right",
      moreText: "Читать дальше",
    })

    const button = document.querySelector(`#seance_${this.galleryIdValue}_trailer`)

    if (button) {
      button.addEventListener("click", (e) => {
        e.preventDefault()
        this.lightbox.openAt(1)
      })
    }
  }
}
