import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { Russian } from "flatpickr/dist/l10n/ru"
import "flatpickr/dist/themes/dark.css"

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static values = {
    showTime: {
      type: Boolean,
      default: false,
    },
    minDate: String,
    maxDate: String,
    inline: {
      type: Boolean,
      default: false,
    },
    date: String,
    defaultHour: Number,
    defaultMinute: Number,
    range: Array,
    mode: {
      type: String,
      default: "single",
    },
    dateFormat: {
      type: String,
      default: "Y-m-d",
    },
  }

  connect() {
    new flatpickr(this.element, {
      enableTime: this.showTimeValue,
      mode: this.modeValue,
      dateFormat: this.dateFormatValue,
      allowInput: true,
      time_24hr: true,
      minuteIncrement: 5,
      minDate: this.minDateValue,
      maxDate: this.maxDateValue,
      locale: Russian,
      inline: this.inlineValue,
      defaultDate: this.defaultDate(),
      defaultHour: this.defaultHourValue || false,
      defaultMinute: this.defaultMinuteValue || false,
    })
  }

  defaultDate() {
    if (this.modeValue === "single") {
      return this.dateValue || false
    } else {
      return this.rangeValue
    }
  }
}
