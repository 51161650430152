import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"
import setupUltimateTurboModal from "ultimate_turbo_modal"
import RailsNestedForm from "@stimulus-components/rails-nested-form" // Start the Stimulus application.
import Clipboard from "@stimulus-components/clipboard"
import ReadMore from "@stimulus-components/read-more"
import { Autocomplete } from "stimulus-autocomplete"

// Start the Stimulus application.
const application = Application.start()
const is_dev = process.env.NODE_ENV === "development"
setupUltimateTurboModal(application)
application.register("nested-form", RailsNestedForm)
application.register("clipboard", Clipboard)
application.register("read-more", ReadMore)
application.register("autocomplete", Autocomplete)

// Configure Stimulus development experience
application.debug = is_dev
application.stimulusUseDebug = is_dev

window.Stimulus = application

// Generic controllers
const controllers = import.meta.glob("./**/*_controller.js", { eager: true })
registerControllers(application, controllers)

// Controllers from components
const componentControllers = import.meta.glob("./../../frontend/components/**/controller.js", {
  eager: true,
})

for (let path in componentControllers) {
  let module = componentControllers[path]

  let name = path
    .match(/frontend\/components\/(.+)\/controller\.js$/)[1]
    .replaceAll("/", "--")
    .replaceAll("_", "-")
  application.register(name, module.Controller)
}
export default application
