import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ticket-form"
export default class extends Controller {
  static targets = ["clientSource", "otherField"]
  static values = {
    saved: { type: String, default: "" },
  }

  connect() {
    this.savedValue = this.otherFieldTarget.value
    this.toggleOtherField()
  }

  toggleOtherField(e) {
    const otherField = this.otherFieldTarget
    const errorHint = otherField.nextElementSibling

    if (this.clientSourceTargets?.find((radio) => radio.checked)?.value !== "other") {
      otherField.classList.add("hidden")
      if (errorHint) {
        errorHint.classList.add("hidden")
      }

      if (otherField.value.length > 0) {
        this.savedValue = otherField.value
      }
      otherField.value = ""
    } else {
      otherField.value = this.savedValue

      otherField.classList.remove("hidden")
      if (errorHint) {
        errorHint.classList.remove("hidden")
      }
    }
  }
}
