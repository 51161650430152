import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import { Russian } from "flatpickr/dist/l10n/ru"
import "flatpickr/dist/themes/dark.css"
import { get } from "@rails/request.js"
import shortcutButtonsPlugin from "shortcut-buttons-flatpickr"

// Connects to data-controller="seances--calendar-date-picker"
export default class extends Controller {
  static values = {
    showTime: Boolean,
    minDate: String,
    maxDate: String,
    inline: Boolean,
    date: String,
  }

  static targets = ["link"]

  connect() {
    this.fp = flatpickr(this.element, {
      enableTime: this.showTimeValue,
      allowInput: true,
      time_24hr: true,
      minuteIncrement: 1,
      minDate: this.minDateValue,
      maxDate: this.maxDateValue,
      locale: Russian,
      inline: this.inlineValue,
      defaultDate: this.dateValue || new Date(),
      plugins: [
        shortcutButtonsPlugin({
          button: [
            {
              label: "Сегодня",
            },
          ],
          onClick: async (index, fp) => {
            let date
            switch (index) {
              case 0: {
                const currentDate = new Date()
                date = currentDate.toISOString().split("T")[0]
                break
              }
            }
            fp.setDate(date)

            await this.dateChanged(date)
          },
        }),
      ],

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onChange: async (selectedDates, dateStr, _instance) => {
        this.dateValue = dateStr
        await this.dateChanged(dateStr)
      },
    })

    this.fp.calendarContainer.setAttribute("data-turbo-temporary", "") // prevent calendar duplication when switching back
  }

  async dateChanged(dateStr) {
    const url = new URL(window.location.href)
    const params = { date: dateStr }
    url.search = new URLSearchParams(params).toString()

    window.history.pushState({}, "", url)

    await get(url.toString(), {
      query: { date: dateStr },
      responseKind: "turbo-stream",
    })
  }
}
