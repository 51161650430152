import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["price", "promoSelector"]
  static values = {
    initialPrice: Number,
  }

  change() {
    const selectedOption = this.promoSelectorTarget.selectedOptions[0]
    const discount = selectedOption.getAttribute("data-discount")
    let discountedPrice = this.initialPriceValue - (this.initialPriceValue * discount) / 100
    if (discountedPrice == 0) {
      discountedPrice = 1
    }
    this.priceTarget.textContent = discountedPrice.toLocaleString("ru-RU", {
      style: "currency",
      currency: "RUB",
      maximumFractionDigits: 0,
    })
  }
}
