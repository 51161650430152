// We reserve Controller for the export name
import { Controller as BaseController } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
import { cable } from "@hotwired/turbo-rails"

export class Controller extends BaseController {
  static targets = ["addBtn", "searchInput", "visitorCard", "hiddenVisitorId"]
  static debounces = ["sendResultToServer"]

  connect() {
    useDebounce(this, { wait: 500 })
    this.moviesChannel = cable.subscribeTo("VisitorsChannel")
  }

  async sendResultToServer(event) {
    const consumer = await this.moviesChannel
    consumer?.perform("find_by_keyword", {
      input_data: this.searchInputTarget.value,
      target: this.searchInputTarget.dataset.searchDomId,
    })
  }

  async chooseVisitor(event) {
    this.clearSelection()

    this.hiddenVisitorIdTarget.value = event.currentTarget.dataset.visitorId

    event.currentTarget.classList.remove("border-gray-700", "bg-gray-800")
    event.currentTarget.classList.add("border-green-500", "bg-gray-700")
    // this.searchInputTarget.scrollIntoView({
    //   behavior: "smooth",
    //   block: "center",
    // })
  }

  clearSelection() {
    this.visitorCardTargets.forEach((article) => {
      article.classList.remove("border-green-500", "bg-gray-700")
      article.classList.add("border-gray-700", "bg-gray-800")
    })
  }

  showAddBtn(event) {
    this.addBtnTarget.classList.remove("hidden")
  }

  hideAddBtn(event) {
    this.addBtnTarget.classList.add("hidden")
  }
}
