import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { currVisitorId: String }

  connect() {
    this.connectLinkElement = document.getElementById("connect-link")
    this.connectLinkElement.parentElement.classList.add("hidden")
  }

  chooseVisitor(event) {
    const connectUrl = new URL(this.connectLinkElement)
    this.clearSelection()
    event.currentTarget.classList.add("border-green-500")

    if (event.currentTarget.dataset.visitorId === this.currVisitorIdValue) {
      connectUrl.searchParams.delete("do_merge")
    } else {
      connectUrl.searchParams.set("do_merge", "true")
    }

    this.connectLinkElement.href = connectUrl
    this.connectLinkElement.parentElement.classList.remove("hidden")
  }

  clearSelection() {
    document.querySelectorAll(".flex .card").forEach((el) => {
      el.classList.remove("border-green-500")
    })
  }
}
