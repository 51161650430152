// flip-controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { showClasses: String, hiddenClasses: String }
  static targets = ["front", "back"]

  connect() {
    this.isFlipped = false
  }

  flip(event) {
    event.preventDefault()
    this.isFlipped = !this.isFlipped
    if (this.isFlipped) {
      this.frontTarget.classList.add(...this.hiddenClassesValue.split(" "))
      this.frontTarget.classList.remove(...this.showClassesValue.split(" "))
      this.backTarget.classList.add(...this.showClassesValue.split(" "))
      this.backTarget.classList.remove(...this.hiddenClassesValue.split(" "))
    } else {
      this.frontTarget.classList.add(...this.showClassesValue.split(" "))
      this.frontTarget.classList.remove(...this.hiddenClassesValue.split(" "))
      this.backTarget.classList.add(...this.hiddenClassesValue.split(" "))
      this.backTarget.classList.remove(...this.showClassesValue.split(" "))
    }
  }
}
