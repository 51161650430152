import * as Sentry from "@sentry/browser";

if (import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'production') {
  Sentry.init({
    dsn: "https://14e09c74c74f4c3dbc1ead02c93ec4d4@o214983.ingest.us.sentry.io/1437132",
    environment: import.meta.env.MODE,
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.replayIntegration({
        mask: [".mask-me"],
        block: [".block-me"],
        ignore: [".ignore-me"],
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}
