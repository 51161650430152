import { Controller } from "@hotwired/stimulus"
import { createApp, ref } from "vue/dist/vue.esm-bundler"
import "../plugins/api"

// Connects to data-controller="vue-mounter"
export default class extends Controller {
  connect() {
    const app = createApp({
      setup() {
        return {
          count: ref(0),
        }
      },
    })
    app.mount("#app")
  }
}
